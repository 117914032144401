<template>
    <dv-border-box8 :dur="5" class="lineSorter">
        <div class="lineSorter_speed">当前分拣机线速度：{{ speed }}m/s</div>
        <div class="lineSorter_all">
            <div style="width: 100%; position: relative; left: 50px;">
                <div class="lineSorter_c">
                    <div class="chute_1" v-for="(item, i) in chuteUps">{{ item.content }}</div>
                </div>
                <div class="lineSorter_c">
                    <div class="car" v-for="(item, i) in items" :key="i"
                        :class="{ 'odd': item.content % 2 === 0, 'even': item.content % 2 !== 0 }">
                        {{ item.content }}
                    </div> 
                    <div class="chute_2"> 尾部格口</div> 
                </div>
                <div class="lineSorter_c">
                    <div class="chute_1" v-for="(item, i) in chuteDowns">{{ item.content }}</div>
                </div>
            </div>
        </div>
    </dv-border-box8>
</template>
  
<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            items: Array.from({ length: 80 }, (_, i) => ({
                content: `${i + 1}`, // 初始内容
            })),
            curCarItems: Array.from({ length: 170 }, (_, i) => ({
                content: `${i + 1}`, // 初始内容
            })),
            timer: null, // 用于存储定时器的ID
            count: 0,
            speed:1.5,
            step: 10000,
            upItems:80,
            chuteUps: Array.from({ length: 29}, (_, i) => ({
                content: `${i + 1}`, // 初始内容
            })),
            chuteDowns: Array.from({ length: 29}, (_, i) => ({
                content: `${i + 1}`, // 初始内容
            })),
        };
    },
     computed: {
    // 使用mapState帮助器绑定state中的count
    ...mapState(['globalVariable','g_carItems', 'g_chuteItems', 'g_speed' ]),
  },

    watch: {
        globalVariable(newVal, oldVal) {
            console.log(`Count has changed from ${oldVal} to ${newVal}`);
        },
        g_carItems(newVal, oldVal) {
            console.log(`g_carItems ${oldVal} to ${newVal}`);
          //  console.log("kkkk" + newVal.curCarItems);
           // console.log('curCarItems:', this.curCarItems.content);
           
            this.curCarItems = newVal.curCarItems.map(number => ({
                content: number.toString(), // 如果你需要字符串类型的content
            }));

    },
    g_chuteItems(newVal, oldVal) {
        console.log(`g_chuteItems ${oldVal} to ${newVal}`);
        this.chuteUps = newVal.chuteUp.map(number => ({
            content: number.toString(), // 如果你需要字符串类型的content
        }));
        this.chuteDowns = newVal.chuteDown.map(number => ({
            content: number.toString(), // 如果你需要字符串类型的content
        }));
    },

    g_speed(newVal, oldVal) {
        console.log(`g_speed ${oldVal} to ${newVal}`);
        this.speed=newVal;
        clearInterval(this.timer);
        this.startTimer();
        console.log( "速度间隔22："+this.step);
    },

  },
    mounted() {
        this.startTimer();
    },
    beforeDestroy() {
        // 组件销毁前清除定时器
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
    methods: {
        updateItems() {
            const lastElement = this.curCarItems.pop();
            this.curCarItems = [lastElement, ...this.curCarItems];
            this.items = this.curCarItems.slice(0, this.upItems);
            //this.speed = 1.5;
        },
        startTimer() {
            this.step = 1000/((this.speed*1000) / 220);
            console.log( "速度间隔："+this.step);
            this.timer = setInterval(this.updateItems, this.step);   
        },
    },


};
</script>
  
<style scoped>
.car {
    width: 14px;
    height: 120px;
    border: solid rgb(70, 81, 236) 1px;
    font-size: 1rem;
    writing-mode: vertical-rl;
    border-radius: 5px;
    display: grid;
    place-items: center;
    text-align: center;
}


.odd {
    background-color: rgb(3, 33, 43);
    /* 奇数的颜色 */
}

.even {
    background-color: rgb(19, 17, 114);
    /* 偶数的颜色 */
}

.lineSorter_c {
    width: 90%;
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
}

.lineSorter {
    height: 320px;
    /* width: 60%; */
    position: relative;
    right: 20px;
    width: 1400px;
    /* background-color: rgb(30, 30, 30); */
    background-color: rgb(1, 1, 50);
    color: rgb(255, 255, 255);
}

.lineSorter_speed {
    font-family: 'Microsoft YaHei';
    font-size: 20px;
    position: relative;
    top: 10px;
    left: 30px;
    color: rgb(13, 124, 228);
}

.lineSorter_all {
    height: 100%;
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
}

.chute_1 {
    width: 50px;
    height: 50px;
    /* border: solid greenyellow 1px; */
    font-size: 1rem;
    display: grid;
    place-items: center;
    text-align: center;
}

.chute_2 {
    width: 30px;
    height: 100px;
    border: solid greenyellow 2px;
    font-size: 1rem;
    writing-mode: vertical-rl;
    border-radius: 10px;
    display: grid;
    place-items: center;
    text-align: center;
}
</style>