// store.js
import { createStore } from 'vuex'


// Create a new store instance.
const store = createStore({
  state: {
    globalVariable: '这是一个全局变量',
    g_chartData: '{"sourceData":[0,0,0,0,0,0,0],"tiemData":["08点","09点","10点","11点","12点","13点","14点"]}',
    g_deviceState:'正常',
    g_barcodeCurrent:'----',
    g_unidentified:'{"idenfied":0,"unidentified":0,}',
    g_normalSorting:'{"normal":123,"unnormal":5,}',
    g_sortingTotal:0,
    g_speed:1.5,
    g_carItems:'',//'{"curCarItems":[1, 2, 3, 4, 5, 6, 7],"upItems":80,}',
    g_formData:'',
    g_chuteItems:'',
  },
  mutations: {
    setGlobalVariable(state, value) {
      state.globalVariable = value;
    },
    update_g_chartData(state, newData) {
      state.g_chartData = newData;
    },
    update_g_deviceState(state, newData) {
      state.g_deviceState = newData;
    },

    update_g_barcodeCurrent(state, newData) {
      state.g_barcodeCurrent = newData;
    },
    update_g_unidentified(state, newData) {
      state.g_unidentified = newData;
    },
    update_g_normalSorting(state, newData) {
      state.g_normalSorting = newData;
    },
    update_g_sortingTotal(state, newData) {
      state.g_sortingTotal = newData;
    },
    update_g_speed(state, newData) {
      state.g_speed = newData;
    },
    update_g_carItems(state, newData) {
      state.g_carItems = newData;
    },
    update_g_formData(state, newData) {
      state.g_formData = newData;
    },
    update_g_chuteItems(state, newData) {
      state.g_chuteItems = newData;
    },

  },

  
})

export default store;


