// src/services/websocket.js

import store from '@/store.js';

export default class WebSocketService {
  constructor(url) {
    this.url = url;
    this.webSocket = null;
    this.callbacks = {};
    this.count = 0;//测试
  }

  connect() {
    if (this.webSocket) {
      console.log("WebSocket 开始链接");
      return; // 如果已经连接，则不重复连接
    }

    this.webSocket = new WebSocket(this.url);

    this.webSocket.onopen = () => {
      console.log('WebSocket 已经连接');
    };

    this.webSocket.onmessage = (event) => {
      //console.log('服务器消息:', event.data);
     
      this.count += 1;
      const datas = {
        sourceData: [120, 200*this.count, 150, 80*this.count, 70, 110, 1300],
        timeData: ['08点', '09点', '10点', '11点', '12点', '13点', '14点'],
      }


      const orDatas = JSON.parse(event.data);

      store.commit('update_g_chartData',orDatas.chartData );  
      store.commit('update_g_deviceState', orDatas.deviceState);
      store.commit('update_g_barcodeCurrent', orDatas.barcodeCurrent);
      store.commit('update_g_unidentified', orDatas.unidentified);
      store.commit('update_g_normalSorting', orDatas.normalSorting); 
      store.commit('update_g_sortingTotal', orDatas.sortingTotal);

      // console.log('orDatas.sortingTotal  111111:',orDatas.sortingTotal);

      store.commit('update_g_speed', orDatas.speed);
      store.commit('update_g_carItems', orDatas.carItems);
      store.commit('update_g_formData', orDatas.formData);
      store.commit('update_g_chuteItems', orDatas.chuteItems);

      // console.log('rrrrrrrr:',orDatas.chuteItems.chuteUp);

      store.commit('setGlobalVariable', this.count+"嘎嘎嘎"); 
     

      if (this.callbacks.message) {
        this.callbacks.message.forEach(cb => cb(event.data));
      }
    };

    this.webSocket.onerror = (error) => {
      console.error('WebSocket Error:', error);
      // 可以触发一个错误事件
    };

    this.webSocket.onclose = () => {
      console.log('WebSocket 链接断开了');
      //store.commit('setGlobalVariable', '通讯断开，尝试刷新页面'); 
      store.commit('update_g_deviceState', '断开');
      
    };
  }



  disconnect() {
    if (this.webSocket) {
      this.webSocket.close();
      this.webSocket = null;
    }
  }

  // 模拟事件系统的一个简单实现：使用回调函数数组
  on(event, callback) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event].push(callback);
  }

  // 示例用法（通常你会在Vue组件中调用这些方法）
  // sendMessage(message) {
  //   if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
  //     this.webSocket.send(message);
  //   }
  // }
}

