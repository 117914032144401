<template>
  <div ref="chart" style="width: 600px; height: 300px;  color: rgb(255, 255, 255);"></div>
</template>

<script>
import * as echarts from 'echarts';
// import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      chart: null,
      chartData: {
        sourceData: [1200, 2000, 1500, 800, 700, 1100, 1300],
        timeData: ['08点', '09点', '10点', '11点', '12点', '13点', '14点'],
      }
    };
  },

  computed: {
    // 使用mapState帮助器绑定state中的count
    ...mapState(['globalVariable', 'g_chartData']),
  },

  watch: {
    globalVariable(newVal, oldVal) {
      // console.log(`Count has changed from ${oldVal} to ${newVal}`);
    },
    g_chartData(newVal, oldVal) {
      // console.log(`数据变化了 ${oldVal} to ${newVal}`);
      this.drawChart(newVal);
    },
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart);
    this.drawChart(this.chartData);
  },
  methods: {
    drawChart(chartData) {
      const option = {
        title: {
          text: '每小时分拣效率统计',
          left: 'center',
          textStyle: {
            color: '#ffffff'
          }

        },
        tooltip: {
          // trigger: 'axis',
          // axisPointer: {
          //   type: 'cross',
          //   label: {
          //     backgroundColor: '#6a7985'
          //   }
          // }
        },
        xAxis: {
          type: 'category',
          // data: ['08点', '09点', '10点', '11点', '12点', '13点', '14点'],
          data: chartData.timeData,
          // name: '横坐标标题' 
          axisLabel: {
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '票数',
          nameTextStyle: {
            color: '#ffffff' // 设置为白色
          },
          axisLabel: {
            textStyle: {
              color: '#ffffff'
            },

          }
        },
        series: [
          {
            name: '数量',
            type: 'bar',
            data: chartData.sourceData,
            itemStyle: {
              color: 'rgb(138,43,226)'
            }
          },
          {
            name: '数量',
            type: 'line',
            data: chartData.sourceData,
            // 可以添加其他折线图的样式配置，如平滑曲线等
            // smooth: true
            lineStyle: {
              color: 'rgb(255,255,0)'
            },
          }
        ]
      };

      this.chart.setOption(option);
    },
  
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>