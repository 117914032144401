<template>
  <dv-border-box-11 class="outerFrame" title="直线分拣数据展示">
    <div style="  width: 100%; display: inline-flex;">
      <div style="height:60px;position:absolute;top:30px;left: 50px;  display: inline-flex;">
        <div style="font-size: 40px; color: rgb(255, 255, 255);margin:0%">{{ nowTime }}</div>
        <div style="color: rgb(255, 255, 255);margin:0%;position:relative; top:20px; left: 50px;">
        {{ nowDate }} {{nowWeek }}</div>
      </div>
      <div style="height:60px;position:absolute;top:30px; right:150px;width: 250px;">
        <div>
          <p style="position:relative;font-size: 20px; color: rgb(255, 255, 255);margin:0%;top: 20px;">
          分拣机状态 : {{deviceState }}</p>
        </div>
        <div :class="['dot', dotColor]" style="position:absolute;left:180px;"></div>

      </div>
    </div>
    <!-- <dv-decoration-2 style="position:absolute;top:80px;width:100%;height:10px;" /> -->
    <dv-decoration-6 style="position:absolute;top:80px;width:100%;height:20px;" />
    <div class="container_1">
      <!-- <div class="datetime" style="background-color: rgb(248, 244, 244);">{{ systemDateTime }}</div> -->
      <div>
        <dv-border-box-2 class="codeBar">
          <div class="codeText">当前条码</div>
        </dv-border-box-2>
        <dv-border-box-10 class="codeBar">
          <div class="codeText">{{ barcodeCurrent }}</div>
        </dv-border-box-10>
        <dv-border-box8 :dur="5" class="codeBarSum">
          <div class="labChart_1">
            <div class="labChart_text">
              <div class="labChart_2">
                已读条码总数：<el-button type="primary" style=" width:100px;">{{ this.unidentified.data[1].value }}</el-button>
              </div>
              <p></p>
              <p></p>
              <div class="labChart_2">
                未读条码总数：<el-button type="warning" style=" width:100px;">{{ this.unidentified.data[0].value }}</el-button>
              </div>
            </div>
            <div>
              <dv-active-ring-chart :config="unidentified" class="chartCode" />
            </div>
          </div>
        </dv-border-box8>
      </div>
      <lineSorterShow ref="lineSorterShow" />
    </div>
    <div class="container_2">
      <div>
        <dv-border-box8 :dur="5" class="efficiency_1">

          <div style=" display: flex;justify-content: center;align-items: center;">
            <div class="labChart_1_2">
              <div class="labChart_text">
                <div class="labChart_2">正常总数：<el-button type="primary"
                    style=" width:80px;  background-color: rgb(76, 228, 88);">{{ this.normalSorting.data[1].value }}</el-button>
                </div>
                <p></p>
                <p></p>
                <div class="labChart_2">收容总数：<el-button type="warning"
                    style=" width:80px;  background-color: rgb(235, 49, 173);">{{this.normalSorting.data[0].value  }}</el-button>
                </div>
              </div>
              <div>
                <dv-active-ring-chart :config="normalSorting" class="chartCode_2" />
              </div>
            </div>
            <div class="lab_sum">
              <div style=" width:200px; height: 50px; font-size: 20px; ">
                <dv-decoration7 style="height:30px;">
                  <div color-white font-300>
                    上机总数
                  </div>
                </dv-decoration7>
              </div>
              <div style=" width:200px;">
                <dv-digital-flop :config="sortingTotal" />
              </div>
            </div>
          </div>

        </dv-border-box8>
        <dv-border-box8 :dur="5" class="efficiency_2">
          <chartx ref="chartx" style=" position: relative; top: 20px;"></chartx>
        </dv-border-box8>
      </div>

      <div class="dataShow">
        <dv-border-box8 :dur="5">
          <div class="table" style="width: 99%; position: relative; top: 5px; left: 5px; height: 95%;">
            实时分拣数据
            <dv-scroll-board :config="formData" style="width:100%;height: 95%; position: relative; top: 10px; " />
          </div>

        </dv-border-box8>
      </div>

    </div>
  </dv-border-box-11>
</template>
  
  
<script>
// import * as echarts from 'echarts';
import chartx from '@/components/chartx.vue';
import lineSorterShow from '@/components/lineSorterShow.vue';
import WebSocketService from '@/services/websocket.js';
import { mapState } from 'vuex';

export default {
  components: {
    chartx,
    lineSorterShow,
  },
  data() {
    // 返回一个包含数据的对象
    return {
      list: [1, 6, 3, 7, 5, 6],
      // 当前的系统时间
      systemDateTime: null,
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      dotColor: 'green-dot',
      deviceState: '正常',
      unidentified: {
        data: [
          { name: '未识别', value: 5 },
          { name: '识别', value: 120 },
        ],
        color: ['rgb(255,165,0)', 'rgb(0,191,255)'] // 自定义颜色数组
      },
      normalSorting: {
        data: [
          { name: '收容', value: 5 },
          { name: '正常', value: 120 },
        ],
        color: ['rgb(235, 49, 173)', 'rgb(76, 228, 88)'] // 自定义颜色数组
      },

      formData: {
        rowNum: 12, //表格行数
        carousel: "single",//page
        // carousel: "page",
        headerBGC: 'rgb(1, 1, 50)',
        oddRowBGC: 'rgb(1, 1, 50)',
        header: ['序号', '条码', '目标格口', '落格格口', '车号', '是否上传数据', '时间'],
        data: [
          ['1', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['2', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['3', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['4', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['5', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['6', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['7', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['8', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['9', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['10', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['11', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['12', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['13', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
          ['14', '1234567890', '10', '10', '30', '已经上传', '2024-8-1 12:34:56'],
        ],
      },
      index: true,
      sortingTotal: {
        number: [100],
        content: '{nt}个',
      },
      barcodeCurrent: '-----',
      webSocketService: null,
      message: null,
    };
  },

  computed: {
    // 使用mapState帮助器绑定state中的count
    ...mapState([
      'globalVariable',
      'g_deviceState',
      'g_barcodeCurrent',
      'g_unidentified',
      'g_normalSorting',
      'g_sortingTotal',
      'g_formData',
    ]),
  },

  watch: {
    globalVariable(newVal, oldVal) {
      // console.log(`Count has changed from ${oldVal} to ${newVal}`);
    },
    g_deviceState(newVal, oldVal) {
      console.log(`g_deviceState数据变化了 ${oldVal} to ${newVal}`);
      if (newVal == '断开') {
        this.deviceState = newVal;
        this.dotColor = 'yellow-dot';

        // 在 Vue 组件的方法中
        this.$confirm('与服务器断开了?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 确认后的操作
          console.log('用户点击了确定');
          location.reload();
        }).catch(() => {
          // 取消后的操作
          console.log('用户点击了取消');
        });

      };

      if (newVal == '正常') {
        this.deviceState = newVal;
        this.dotColor = 'green-dot';
      };

      if (newVal == '故障') {
        this.deviceState = newVal;
        this.dotColor = 'red-dot';
      }
      else {
        this.deviceState = newVal;
        this.dotColor = 'red-dot';
      };


    },

    g_barcodeCurrent(newVal, oldVal) {
      console.log(`g_barcodeCurrent ${oldVal} to ${newVal}`);
      this.barcodeCurrent = newVal;
    },

    g_unidentified(newVal, oldVal) {
      console.log(`g_unidentified ${oldVal} to ${newVal}`);
      this.unidentified.data[0].value = newVal.unidentified;
      this.unidentified.data[1].value = newVal.identified;
    },

    g_normalSorting(newVal, oldVal) {
      console.log(`g_normalSorting ${oldVal} to ${newVal}`);
      this.normalSorting.data[0].value = newVal.unnormal;
      this.normalSorting.data[1].value = newVal.normal;
    },

    g_sortingTotal(newVal, oldVal) {
        console.log(`g_sortingTotal ${oldVal} to ${newVal}`);
        this.sortingTotal.number[0] = newVal;
    },

    g_formData(newVal, oldVal) {
        console.log(`g_formData ${oldVal} to ${newVal}`);

        this.formData.data=newVal.data;
    },


  },


  created() {

    this.data = this.loadFile('config.txt');
    console.log(this.data);

    this.currentTime();
    this.webSocketService = new WebSocketService(this.data);
    this.webSocketService.connect();

  
    this.webSocketService.on('message', (data) => {
      // console.log('主页面:', data);
      this.message = data;
    });

  },
  beforeUnmount() {
    this.webSocketService.disconnect();
  },
  mounted() {
    this.timer = setInterval(() => { this.setNowTimes() }, 1000);
    document.title = '直线分拣实时数据';
    console.log(this.$store.state.globalVariable); // 输出: 这是一个全局变量
  },
  methods: {
    currentTime() {
      this.systemDateTime = new Date().toLocaleString()
      this.timerID && clearInterval(this.timerID)
      this.timerID = setInterval(() => {
        this.systemDateTime = new Date().toLocaleString()
      }, 1000)
    },
    setNowTimes() {
      //获取当前时间
      let myDate = new Date()
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm = myDate.getMonth() + 1
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },

    changeConfig() {
      this.config1.number += Math.random() * 100;
    },

    sendMessage() {
      if (this.webSocketService.webSocket && this.webSocketService.webSocket.readyState === WebSocket.OPEN) {
        this.webSocketService.webSocket.send("收到信息");
      }
    },
    loadFile(name) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/${name}`, false);
    xhr.overrideMimeType('text/plain');
    xhr.send(null);
    return xhr.responseText;
  },

  },
};

</script>
  
    
<style scoped>
#chart {
  width: 100%;
  height: 100%;
}

.container {
  /*display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    justify-items: center;   justify-content: space-between; 或者使用 stretch、start、end 等 */
  width: 100%;
  display: inline-flex;
  justify-items: center;
  justify-content: space-between;
  /* 或者使用其他值，比如space-around */
  padding: 10px;
}

.container>* {
  flex-shrink: 0;
  width: calc((100% - 40px) / 3);
  /* 假设左右padding共20px，元素间距共20px */
  margin-right: 10px;
}

.container>*:last-child {
  margin-right: 0;
}

.container_2 {
  position: absolute;
  top: 430px;
  left: 10px;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: space-between;

}

.container_1 {
  position: absolute;
  top: 90px;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding: 10px;
}

.dataShow {
  position: relative;
  left: -20px;
  width: 1250px;
}


.dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  vertical-align: top;
  /*margin-left: 5px;  调整点与文字之间的间距 */
  animation: blink 1s linear infinite;
  /* 定义闪烁动画 */
}

.red-dot {
  background-color: rgb(240, 123, 76);
}

.green-dot {
  background-color: rgb(172, 228, 69);
}


@keyframes blink {

  0%,
  100% {
    opacity: 1;
    /* 完全可见 */
  }

  50% {
    opacity: 0.5;
    /* 半透明 */
  }
}

.codeBar {
  height: 40px;
  width: 450px;
  margin-bottom: 5px;

}

.codeText {
  text-align: center;
  position: relative;
  top: 10px;

}

.codeBarSum {
  height: 230px;
  width: 450px;
  /* background-color: rgb(30, 30, 30); */
  background-color: rgb(1, 1, 50);

}

.labChart_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.labChart_1_2 {
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labChart_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.labChart_text {
  position: relative;
  left: 10px;
  width: 350px;
  font-size: 10px;
}


.outerFrame {
  height: 100vh;
  width: 100%;
  background-color: rgb(1, 1, 34);
  color: rgb(255, 255, 255);
}

.chartCode {
  width: 250px;
  height: 250px;
  position: relative;
  margin-left: 0;
  margin-top: 0;
}

.chartCode_2 {
  width: 200px;
  height: 200px;
}



.efficiency_1 {
  height: 180px;
  width: 600px;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.efficiency_2 {
  height: 300px;
  width: 600px;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}
</style>