
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    // meta: { title: 'Home Page' }
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router


